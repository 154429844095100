<template>
    <div class="filter-by-letter">
        <nav class="filter-by-letter__nav">
            <NuxtLink
                no-prefetch
                v-for="(letter, index) in filterLetters"
                :key="index"
                :to="path + letter.path"
                class="filter-by-letter__nav-item"
                rel="follow"
                :class="
                    active === letter.name
                        ? 'bg-site-primary text-white'
                        : 'bg-white text-gray-800 hover:text-site-primary'
                "
                @click="changeSelected(letter.name)"
            >
                <span>
                    <img
                        v-if="letter.icon"
                        :src="active === 'popular' ? letter.icon2 : letter.icon"
                        alt="image"
                    />
                    {{ letter.name }}
                </span>
            </NuxtLink>
        </nav>

        <div
            class="filter-by-letter__content"
            :class="limit ? 'filter-by-letter__content_limit' : 'filter-by-letter__content_nolimit'"
        >
            <template v-for="(letter, index) in filteredFilterLetters">
                <div
                    v-if="letter.name !== 'popular' && letter.data.length > 0"
                    :key="index"
                    class="filter-by-letter__content-child"
                >
                    <p class="filter-by-letter__content-child-letter">{{ letter.name }}</p>

                    <div v-if="limit" class="filter-by-letter__content-child-items">
                        <NuxtLink
                            no-prefetch
                            v-for="(item, key) in letter.data.slice(0, 10)"
                            :key="key"
                            :to="path + '/' + item.slug"
                            rel="follow"
                            class="item-wrapper"
                        >
                            <picture
                                class="item-wrapper_image"
                                :class="path === '/' + route ? 'bg-white' : ''"
                            >
                                <img
                                    v-if="item.image"
                                    :src="item.image.url"
                                    :alt="item.name"
                                    :onerror="`this.onerror=null;this.src='${
                                        defaultImage[path === '/' + route ? 'store' : 'category']
                                    }'`"
                                />
                            </picture>
                            {{ item.name }}
                        </NuxtLink>
                    </div>

                    <div v-if="!limit" class="filter-by-letter__content-child-items columns-2 md:columns-3">
                        <NuxtLink
                            no-prefetch
                            v-for="(item, key) in letter.data"
                            :key="key"
                            rel="follow"
                            :to="path + '/' + item.slug"
                            class="item-wrapper"
                        >
                            <picture
                                class="item-wrapper_image"
                                :class="path === '/' + route ? 'bg-white' : ''"
                            >
                                <img
                                    v-if="item.image"
                                    :src="item.image.url"
                                    :alt="item.name"
                                    :onerror="`this.onerror=null;this.src='${
                                        defaultImage[path === '/' + route ? 'store' : 'category']
                                    }'`"
                                />
                            </picture>
                            {{ item.name }}
                        </NuxtLink>
                    </div>

                    <NuxtLink
                        no-prefetch
                        v-if="letter.data.length > 10 && $route.params.slug === undefined"
                        class="see-more"
                        rel="follow"
                        :to="path + letter.path"
                    >
                        {{ $lang.components.formFilterByLetter.see_more }}
                        <img :src="$assets.primary.arrowRightCategory" alt="icon show more" />
                    </NuxtLink>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { Models } from '~/types/models'
import { useVariantsDictionary } from '~/composables/VariantsDictionary'

interface FilterLetters {
    name: string
    path: string
    data: Array<Models.Category | Models.Store>
    icon?: string
    icon2?: string
    class?: string
}

export default defineComponent({
    name: 'FilterByLetter',
    props: {
        path: {
            type: String,
            default: '',
        },
        info: {
            type: Object as PropType<{ [key: string]: Array<Models.Category | Models.Store> }>,
            required: true,
        },
    },
    data() {
        const { defaultImages } = useVariantsDictionary()
        const { $lang } = useNuxtApp()
        return {
            route: $lang.routes.brands,
            defaultImage: defaultImages,
            filterLetters: [
                {
                    name: 'popular',
                    path: '',
                    icon2: this.$assets.white.popular,
                    icon: this.$assets.primary.diamond,
                    class: 'font-semibold',
                    data: [],
                },
                { name: 'a', path: '/a', data: [] },
                { name: 'b', path: '/b', data: [] },
                { name: 'c', path: '/c', data: [] },
                { name: 'd', path: '/d', data: [] },
                { name: 'e', path: '/e', data: [] },
                { name: 'f', path: '/f', data: [] },
                { name: 'g', path: '/g', data: [] },
                { name: 'h', path: '/h', data: [] },
                { name: 'i', path: '/i', data: [] },
                { name: 'j', path: '/j', data: [] },
                { name: 'k', path: '/k', data: [] },
                { name: 'l', path: '/l', data: [] },
                { name: 'm', path: '/m', data: [] },
                { name: 'n', path: '/n', data: [] },
                { name: 'ñ', path: '/ñ', data: [] },
                { name: 'o', path: '/o', data: [] },
                { name: 'p', path: '/p', data: [] },
                { name: 'q', path: '/q', data: [] },
                { name: 'r', path: '/r', data: [] },
                { name: 's', path: '/s', data: [] },
                { name: 't', path: '/t', data: [] },
                { name: 'u', path: '/u', data: [] },
                { name: 'v', path: '/v', data: [] },
                { name: 'w', path: '/w', data: [] },
                { name: 'x', path: '/x', data: [] },
                { name: 'y', path: '/y', data: [] },
                { name: 'z', path: '/z', data: [] },
            ] as FilterLetters[],
            active: 'popular',
        }
    },
    computed: {
        filteredFilterLetters(): FilterLetters[] {
            return this.filterLetters.filter(
                (letter) => this.active === 'popular' || this.active === letter.name,
            )
        },
        limit(): boolean {
            return this.$route.params.slug === undefined
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(val) {
                if (val.params.slug === undefined) {
                    this.active = 'popular'
                } else {
                    this.active = val.params.slug
                }
            },
        },
    },
    mounted() {
        for (const letter in this.info) {
            const letterData = this.filterLetters.find(({ name }) => name === letter)
            if (letterData) {
                letterData.data = this.info[letter] || []
            }
        }
    },
    methods: {
        changeSelected(letter: any) {
            this.active = letter
        },
    },
})
</script>

<style lang="postcss" scoped>
.filter-by-letter {
    @apply flex flex-col;
    &__nav {
        @apply text-center;
        &-item {
            @apply mr-1 mt-1 inline-block rounded-lg border border-gray-200 px-5 py-2 text-center text-base font-medium capitalize;
            span {
                @apply flex gap-1;
                img {
                    @apply h-3 self-center;
                }
            }
        }
    }

    &__content {
        &_limit {
            @apply mt-5 columns-2 md:columns-4;
        }
        &_nolimit {
            @apply mt-5;
        }
        &-child {
            @apply mb-5 break-inside-avoid font-medium;
            &-letter {
                @apply mb-5 text-4xl uppercase text-gray-800;
            }
            &-items {
                @apply space-y-0.5;
            }
            .see-more {
                @apply flex gap-1 text-site-primary;
                img {
                    @apply w-4 self-center;
                }
            }
            .item-wrapper {
                @apply flex w-fit break-inside-avoid items-center gap-2 space-y-0.5 font-medium text-gray-800;
                &_image {
                    @apply flex h-8 w-8 px-0.5;
                    img {
                        @apply mx-auto object-contain;
                    }
                }
            }
        }
    }
}
.active {
    @apply text-site-primary;
}
</style>
